body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #DCCDB0;
}


body::before {
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 0;
  content: url(assets/neufville_crest.png);
  opacity: .2;
  display: inline;
  transform: translate(-50%, -25px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}